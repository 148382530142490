



































import Vue from 'vue';
import Component from 'vue-class-component';
import {MerchantAPI} from "@/api";
import Organization from "../models/organization";

@Component
export default class ReplenishBalance extends Vue {
  private amount: number = 0;
  private isLoading: boolean = false;
  private organization: Organization;

  public mounted() {
    MerchantAPI.organizationFind().then((organization: Organization) => {
      this.organization = organization;
    });
  }

  private async onReplenishBalance() {
    this.isLoading = true;

    if (this.amount <= 0) {
      this.isLoading = false;
      this.$buefy.toast.open({
        message: `<b>${this.$t('InvalidAmount')}</b>`,
        position: 'is-bottom',
        type: 'is-danger',
      });
      return false;
    }

    if (!this.validate()) {
      return false;
    }

    MerchantAPI.replenishBalance(this.amount, this.organization)
      .then(() => {
        this.$buefy.toast.open({
          message: `<b>${this.$t('BalanceReplenished')}</b>`,
          position: 'is-bottom',
          type: 'is-success',
        });
      }).catch((err: any) => {
        if (err.response === undefined) {
          this.$buefy.toast.open({
            message: `<b>${this.$t('NetworkError')}</b>`,
            position: 'is-bottom',
            type: 'is-danger',
          });

          return
        }

        if (err.response.status === 500) {
          this.$buefy.toast.open({
            message: `<b>${err.response.data.message}</b>`,
            position: 'is-bottom',
            type: 'is-danger',
          });

          return;
        }
      }).finally(() => {
        this.isLoading = false;
      });
  }

  private validate() {
    const form = this.$refs['vuejs-form-data'] as HTMLFormElement;
    if (form.checkValidity && !form.checkValidity()) {
      const elements = [...form.elements] as HTMLElement[];
      elements.forEach((e) => e.focus());
      // form.reportValidity();
      return false;
    }

    return true;
  }
}
